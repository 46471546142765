import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "ht-loader" }, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "htfade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.message)
            ? (_openBlock(), _createElementBlock("div", {
                class: "label",
                key: _ctx.message
              }, _toDisplayString(_ctx.message), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}