<template>
  <AgCard>
    <ag-row>
      <ag-column xs="12" sm="12" md="6" cols="12">
        <AgDiv class="combine_flight">
          <ag-row test-id="">
            <ag-column xs="12" sm="12" md="6" cols="12">
              <HotelLocationAutoComplete
                @update:location="searchLocation"
                @onSelect:location="onSelectLocation"
                label="From"
                name="location"
                :items="locations"
                :value="$store.state.hotelModule.selectedLocation"
                :is-loading="$store.getters.isLocationsFetching"
              />
            </ag-column>

            <ag-column xs="12" sm="12" md="6" cols="12">
              <ag-calendar
                label="Check In"
                v-model="$store.state.hotelModule.checkin_date"
                :min-date="new Date()"
                :date="$store.state.hotelModule.checkin_date"
                calendar-name="checkin"
                @update:checkin="changeCheckInDate"
                IconName="calendar"
              />
            </ag-column>
          </ag-row>
        </AgDiv>
      </ag-column>
      <ag-column xs="12" sm="12" md="6" cols="12">
        <AgDiv>
          <ag-row>
            <ag-column xs="12" sm="12" md="6" cols="12">
              <ag-calendar
                label="Check Out"
                v-model="$store.state.hotelModule.checkout_date"
                :min-date="checkoutMinDate"
                :date="$store.state.hotelModule.checkout_date"
                calendar-name="checkout"
                @update:checkout="changeCheckOutDate"
                IconName="calendar"
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" cols="12">
              <ag-heading title="Traveler" variant="label"></ag-heading>
              <AgTravelerCountDropdown
                test-id=""
                :label="renderTravelerLabel"
                class="hotel_traveler"
              >
                <template #Items>
                  <AgTravelerCountItem
                    icon="adultIcon"
                    name="AdultCount"
                    :count="$store.state.hotelModule.hotel_adult_count"
                    @update:AdultCount="updateAdultCount"
                    label="Adult (18+)"
                    test-id=""
                  />
                  <AgTravelerCountItem
                    icon="childrenIcon"
                    name="ChildCount"
                    @update:ChildCount="updateChildCount"
                    :count="$store.state.hotelModule.hotel_child_count"
                    label="Children (Under 1 to less then 12)"
                    test-id=""
                  >
                    <template #childInfo>
                      <AgDiv test-id="" class="margin_top_20 px-15 px-5">
                        <AgRow test-id="">
                          <AgColumn
                            test-id=""
                            sm="12"
                            md="6"
                            v-for="(item, index) in hotelChildAges"
                            :key="index"
                          >
                            <AgSelect
                              :value="item"
                              :items="childAges"
                              :label="renderAgeSelecterLabel(index)"
                              test-id=""
                              variant="outlined"
                              @change="(value: any) => handleAgeSelectChange(value, index)"
                              class="child_age_select"
                            />
                          </AgColumn>
                        </AgRow>
                      </AgDiv>
                    </template>
                  </AgTravelerCountItem>
                </template>
              </AgTravelerCountDropdown>
            </ag-column>
          </ag-row>
        </AgDiv>
      </ag-column>
    </ag-row>
    <AgDiv class="text-center margin_top_20">
      <a-g-button
        test-id="fligh-search"
        :is-loading="isPropertiesFetching"
        @click="handleSearchClick"
      >
        Search Hotels
      </a-g-button>
    </AgDiv>
  </AgCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { addDays, format } from "date-fns";
import { debounce } from "lodash";

import HotelLocationAutoComplete from "@/ag-portal-common/components/hotel/HotelLocaltionAutoComplete.vue";
import { HotelSearchSuggestion } from "@/ag-portal-common/types/hotel";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import { ChildrenAges } from "@/ag-portal-common/constants/hotels";
import { PATH } from "@/ag-portal-common/constants/path";
import { formatQueryParamsforHotelSearch } from "../utils";
import analyticsService from "@/analytics.service";
import { HOTEL_ANALYTICS_EVENTS } from "@/modules/Hotel/constants/analyticsEvents";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
export default defineComponent({
  name: "HotelSearchBar",
  components: {
    HotelLocationAutoComplete,
  },
  data() {
    return {
      locationQuery: "",
      childAges: ChildrenAges,
    };
  },
  methods: {
    searchLocation(value: string) {
      this.locationQuery = value;
    },
    onSelectLocation(value: any) {
      this.$store.commit("saveSelectedLocation", value);
    },
    searchLocations(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchLocations", value);
      }
    },
    changeCheckInDate(newDate: Date) {
      this.$store.commit("saveCheckInDate", newDate);
    },
    changeCheckOutDate(newDate: Date) {
      this.$store.commit("saveCheckOutDate", newDate);
    },
    updateAdultCount(value: number) {
      this.$store.commit("saveHotelAdultCount", value);
    },
    updateChildCount(value: number) {
      this.$store.commit("saveHotelChildCount", value);
    },
    handleAgeSelectChange(value: string, index: number) {
      const ages = this.$store.state.hotelModule.hotel_child_age || [];
      ages[index] = value;
      this.$store.commit("updateChildrenAges", ages);
    },
    renderAgeSelecterLabel(index: number) {
      return `Child ${index + 1} Age`;
    },
    handleSearchClick() {
      const {
        hotel_adult_count,
        hotel_child_age,
        hotel_child_count,
        selectedLocation,
        checkin_date,
        checkout_date,
      } = this.$store.state.hotelModule;
      // before it allowed empty selected so it crashes
      if (selectedLocation !== null) {
        const query = formatQueryParamsforHotelSearch(
          hotel_adult_count,
          hotel_child_age,
          selectedLocation,
          checkin_date,
          checkout_date
        );
        const path = `${PATH.HOTELS_SEARCH_RESULT}${formatQueryPath(query)}`;

        const payload = {
          to: `${selectedLocation.display_name}, ${selectedLocation.sub_display_name}`,
          type: selectedLocation.type,
          "check-in": checkin_date,
          "check-out": checkout_date,
          "adult-travler-count": hotel_adult_count,
          "child-travler-count": hotel_child_count,
        };

        analyticsService.logActionEvent(
          HOTEL_ANALYTICS_EVENTS.HOTEL_SEARCH,
          payload
        );

        this.$router.push(path);
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Please Enter A Hotel";
        notificationService.triggerNotification();
      }
    },
  },
  computed: {
    isPropertiesFetching(): boolean {
      return this.$store.getters.isPropertiesFetching;
    },
    locations(): HotelSearchSuggestion[] {
      return this.$store.getters.locations as Array<HotelSearchSuggestion>;
    },
    checkoutMinDate(): Date {
      return addDays(this.$store.state.hotelModule.checkin_date, 1);
    },
    hotelChildAges(): number[] {
      return this.$store.getters.hotelChildAges;
    },
    renderTravelerLabel() {
      const { hotel_adult_count, hotel_child_count } =
        this.$store.state.hotelModule;
      let label = "";
      if (hotel_adult_count > 0) {
        label += `${hotel_adult_count} Adult`;
        if (hotel_adult_count > 1) {
          label += "s";
        }
      }

      if (hotel_child_count > 0) {
        if (label !== "") {
          label += ", ";
        }
        label += `${hotel_child_count} Child`;
        if (hotel_child_count > 1) {
          label += "ren";
        }
      }

      return label;
    },
  },
  watch: {
    locationQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchLocations(value);
      }, 500),
      immediate: true,
    },
    "$store.state.hotelModule.hotel_child_count": {
      handler: function (value) {
        const ages = this.$store.state.hotelModule.hotel_child_age || [];
        if (value > ages?.length) {
          ages.push(0);
        } else if (value < ages?.length) {
          ages.pop();
        }
        this.$store.commit("updateChildrenAges", ages);
      },
      immediate: true,
    },
  },
});
</script>
