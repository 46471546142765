<template>
  <ag-loader v-if="isPropertiesFetching" />

  <template v-else>
    <ag-heading variant="h2" title="Search Results" />
    <HotelSearchBar />
    <!-- Filters -->
    <AgCard class="flightFilterWrap">
      <AgDiv class="head"> Filter</AgDiv>
      <AgDiv class="d-flex">
        <AgFilterDropdown test-id="" label="Price Range">
          <template #Items>
            <AgPriceRange
              @update:rangeSliderValue="handleUpdateRange"
              :min="minPriceRange"
              :max="maxPriceRange"
              :thumb-size="20"
              thumb-label="always"
              test-id=""
            ></AgPriceRange>
          </template>
        </AgFilterDropdown>

        <AgFilterDropdown
          test-id=""
          label="Property Rating"
          @click="(e:MouseEvent) => e.stopPropagation()"
        >
          <template #Items>
            <AgCheckbox
              v-for="(item, index) in ratingFilter"
              @click="(e:MouseEvent) => e.stopPropagation()"
              v-model="localSelectedFilters.propertyRating"
              :key="index"
              :value="item.value"
              :label="item.label"
              test-id=""
            />
          </template>
        </AgFilterDropdown>

        <AgFilterDropdown test-id="" label="Supplier">
          <template #Items>
            <AgCheckbox
              v-for="(item, index) in supplierFilter"
              @click="(e:MouseEvent) => e.stopPropagation()"
              v-model="localSelectedFilters.supplier"
              :key="index"
              :value="item.value"
              :label="item.label"
            />
          </template>
        </AgFilterDropdown>

        <AgFilterDropdown test-id="" label="Booking Policy">
          <template #Items>
            <AgCheckbox
              v-for="(item, index) in refundableFilters"
              @click="(e:MouseEvent) => e.stopPropagation()"
              v-model="localSelectedFilters.bookingPolicy"
              :key="index"
              :value="item.value"
              :label="item.label"
              test-id=""
            ></AgCheckbox>
          </template>
        </AgFilterDropdown>
      </AgDiv>
    </AgCard>

    <!-- Filter Chips -->
    <AgRow test-id="">
      <AgColumn test-id="" sm="8" md="8" cols="12">
        <AgDiv
          test-id=""
          class="d-flex margin_bottom_10"
          style="flex-wrap: wrap"
        >
          <AgFlightChip
            v-for="(item, index) in selectedRatingFilter"
            @click="handleRemoveChip(index, 'propertyRating')"
            :key="index"
            :value="item"
            test-id=""
          />
          <AgFlightChip
            v-for="(item, index) in selectedBookingPolicyFilter"
            @click="handleRemoveChip(index, 'bookingPolicy')"
            :key="index"
            :value="item"
            test-id=""
          />
        </AgDiv>
      </AgColumn>
    </AgRow>

    <template v-if="filteredProperties.length > 4">
      <Transition name="htfade" mode="out-in">
        <MHotelSearchLoader
          v-show="isLoadingMore && hasNext"
          :message="loaderMessage"
        />
      </Transition>
    </template>

    <div class="hotels-list">
      <template v-for="(item, index) in filteredProperties" :key="index">
        <MHotelSearchListItem
          :id="`${item.property_id}-${index}`"
          class="hotel-search-list-item"
          :thumbnail="formatUrl(item.main_image_url)"
          :title="item.property_name"
          :address="
            formatAddress(
              item.address_line_1,
              item.city_name,
              item.country_name
            )
          "
          :prepayment-text="getPrepaymentText(item.issue_now_pay_later)"
          :refund-type="formatRefundable(item.non_refundable)"
          starting-from-text="Starting From"
          :price="
            formatPrice(item.gross_price.value, item.gross_price.currency)
          "
          :days-info="getNights"
          :rating="formatRating(item.rating)"
          :traveler-info="getTravelersCount"
          :supplier-name="item.supplier"
          @on:click="getPropertyDetailRouteHandler(item.property_id)"
        ></MHotelSearchListItem>
      </template>
    </div>

    <Transition name="htfade" mode="out-in">
      <MHotelSearchLoader
        v-show="isLoadingMore && hasNext"
        :message="loaderMessage"
      />
    </Transition>

    <!-- Results no found -->
    <AgNotFound
      v-if="showNoResult()"
      test-id=""
      heading="No Results Found"
      description="Please Try Modify Your Filters OR Try Again"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import HotelSearchBar from "../components/HotelSearchBar.vue";
import MHotelSearchListItem from "../components/MHotelSearchListItem.vue";
import MHotelSearchLoader from "../components/MHotelSearchLoader.vue";

// Types
import { SearchFilters } from "../interfaces/hotels.interface";
import {
  HotelPropertyResponse,
  HotelSearchSuggestion,
  Property,
} from "@/ag-portal-common/types/hotel";
import {
  DEFAULT_FILTERS,
  HOTEL_BOOKING_POLICY_FILTERS,
  HOTEL_BOOKING_SUPPLIER_FILTERS,
  HOTEL_PROPERTY_RATINGS_FILTERS,
} from "@/ag-portal-common/constants/hotels";
import {
  formatImageUrl,
  formatNumber,
  formatQueryPath,
  formatStringToRoutePath,
  getCurrencyFormatter,
} from "@/ag-portal-common/utils/helpers";
import { differenceInDays } from "date-fns";
import { formatQueryParamsforHotelSearch } from "../utils";
import { PATH } from "@/ag-portal-common/constants/path";
import analyticsService from "@/analytics.service";
import { HOTEL_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import { getPreHotelsSuggestions } from "@/ag-portal-common/constants/preHotelSuggestions";
import { LOADER_MESSAGES } from "../constants/messages";

interface HotelSearchResultsInterface {
  localSelectedFilters: SearchFilters;
  filteredProperties: Array<Property>;
  minPriceRange: number;
  maxPriceRange: number;
  allowRouterQueryRequest: boolean;
  currentPage: number;
  items: Element[];
  itemObserver: IntersectionObserver | null;
  apiCallInProgress: boolean;
  isLoadingMore: boolean;

  loaderMessages: typeof LOADER_MESSAGES;
  loaderMessageIndex: number;
}

interface HotelFiltersInterface {
  label: string;
  value: string;
}

interface HotelPropertyDetails {
  hotel_adult_count: number;
  hotel_child_count: Array<number>;
  selectedLocation: HotelSearchSuggestion;
  checkin_date: Date;
  checkout_date: Date;
}

enum HotelPropertiesApiMethod {
  V1 = "searchProperties",
  V2 = "searchPropertiesV2",
}

export default defineComponent({
  name: "HotelSearchResults",
  components: {
    HotelSearchBar,
    MHotelSearchListItem,
    MHotelSearchLoader,
  },
  data(): HotelSearchResultsInterface {
    return {
      localSelectedFilters: {
        propertyRating: [],
        bookingPolicy: [],
        rangeSlider: 0,
        supplier: [],
      },
      filteredProperties: [],
      minPriceRange: 0,
      maxPriceRange: 0,
      allowRouterQueryRequest: true,
      currentPage: 1,
      items: [],
      itemObserver: null,
      apiCallInProgress: false,
      isLoadingMore: false,

      loaderMessages: LOADER_MESSAGES,
      loaderMessageIndex: 0,
    };
  },
  computed: {
    ratingFilter(): HotelFiltersInterface[] {
      return HOTEL_PROPERTY_RATINGS_FILTERS;
    },
    supplierFilter(): HotelFiltersInterface[] {
      return HOTEL_BOOKING_SUPPLIER_FILTERS;
    },
    refundableFilters(): HotelFiltersInterface[] {
      return HOTEL_BOOKING_POLICY_FILTERS;
    },
    selectedSupplierFilter(): string[] {
      const filters: HotelFiltersInterface[] = HOTEL_BOOKING_SUPPLIER_FILTERS;
      const selectedSupplierFilter: Array<string> =
        this.localSelectedFilters.supplier;

      if (filters.length === selectedSupplierFilter.length) {
        return ["supplier"];
      }

      const selectedFilter = filters
        .filter((item) => selectedSupplierFilter.includes(item.value))
        .map((item) => item.label);

      return selectedFilter;
    },
    selectedRatingFilter(): string[] {
      const filters: HotelFiltersInterface[] = HOTEL_PROPERTY_RATINGS_FILTERS;
      const selectedRatingFilter: Array<string> =
        this.localSelectedFilters.propertyRating;

      if (filters.length === selectedRatingFilter.length) {
        return ["All Ratings"];
      }

      const selectedFilter = filters
        .filter((item) => selectedRatingFilter.includes(item.value))
        .map((item) => item.label);

      return selectedFilter;
    },
    selectedBookingPolicyFilter() {
      const filters: HotelFiltersInterface[] = HOTEL_BOOKING_POLICY_FILTERS;
      const selectedBookingPolicy: Array<string> =
        this.localSelectedFilters.bookingPolicy;

      if (filters.length === selectedBookingPolicy.length) {
        return ["All Booking Policy"];
      }

      const selectedFilter = filters
        .filter((item) => selectedBookingPolicy.includes(item.value))
        .map((item) => item.label);

      return selectedFilter;
    },
    initialPriceRange(): string {
      const currencyFormatter = getCurrencyFormatter();
      const amount = currencyFormatter.format(this.minPriceRange);

      return amount;
    },
    getNights(): string {
      const params = this.$route.query;

      const checkinDate = params.checkin?.toString();
      const checkoutDate = params.checkout?.toString();

      if (!(checkinDate && checkoutDate)) {
        return "";
      }

      const nights = differenceInDays(
        new Date(checkoutDate),
        new Date(checkinDate)
      );

      return `${nights} Night${nights > 1 ? "s" : ""}`;
    },
    getTravelersCount(): string {
      const params = this.$route.query;
      const adultCount = params.adult?.toString();
      const childCount = params.child?.toString();

      const totalChildCount = childCount ? childCount.split(",").length : 0;
      const totalCount = Number(adultCount) + totalChildCount;

      return `Traveler${totalCount > 1 ? "s" : ""} ${totalCount}, Room 1`;
    },
    isPropertiesFetching(): boolean {
      const value: boolean = this.$store.getters.isPropertiesFetching;

      return value;
    },
    hasNext(): boolean {
      const value: boolean = this.$store.getters.hasNext;

      return value;
    },
    loaderMessage(): string {
      return this.loaderMessages[this.loaderMessageIndex];
    },
  },
  methods: {
    grabHotelListItems(): Element[] {
      return [...document.querySelectorAll(".hotel-search-list-item")];
    },

    async onFetchPropertiesOnScrollHandler(): Promise<void> {
      if (!this.isLoadingMore) {
        this.isLoadingMore = true;

        await this.onFetchMorePropertiesHandler();

        if (this.itemObserver) {
          this.itemObserver.disconnect();
        }

        if (this.hasNext) {
          this.onInitiateScrollViewHandler();
        }

        this.isLoadingMore = false;
      }
    },
    onInitiateScrollViewHandler(): void {
      const properties = this.grabHotelListItems();

      this.itemObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const propertyId = entry.target.id;
            const property = properties.find((p) => p.id === propertyId);

            if (
              property &&
              properties.indexOf(property) === properties.length - 1 &&
              entry.isIntersecting
            ) {
              this.onFetchPropertiesOnScrollHandler();
            }
          });
        },
        {
          root: null,
          threshold: 0.5,
        }
      );

      properties.forEach((property: Element) => {
        const element = document.getElementById(property.id);

        if (element && this.itemObserver) {
          this.itemObserver.observe(element);
        }
      });
    },

    async onFetchMorePropertiesHandler(): Promise<void> {
      this.onChangeLoaderMessageHandler();
      await this.onFetchPropertiesHandler(HotelPropertiesApiMethod.V2);
    },
    async onFetchPropertiesHandler(
      propertiesApiMethod = HotelPropertiesApiMethod.V1
    ): Promise<void> {
      const params = this.$route.query;
      const name = params.name?.toString();
      const sub_name = params.sub_name?.toString();
      const destination = params.destination?.toString();
      const type = params.type?.toString();
      const checkInDate = params.checkin?.toString();
      const checkOutDate = params.checkout?.toString();
      const adultCount = params.adult?.toString();
      const childCount = params.child?.toString();

      if (!(checkInDate && checkOutDate)) {
        return;
      }

      const totalChildCount = childCount
        ? childCount.split(",").map((x) => ({ age: Number(x) }))
        : [];

      const saveSelectedLocationPayload = {
        display_name: name,
        sub_display_name: sub_name,
        label: `${name}, ${sub_name}`,
        search_id: destination,
        type,
      };

      let PAGE_NUMBER = 1;

      if (propertiesApiMethod === HotelPropertiesApiMethod.V2) {
        this.currentPage = this.currentPage + 1;
        PAGE_NUMBER = this.currentPage;
      }

      const searchPropertiesPayload = {
        filters: DEFAULT_FILTERS,
        checkin: checkInDate,
        checkout: checkOutDate,
        id: destination,
        page: PAGE_NUMBER,
        rooms_occupancy: [
          {
            adults: Number(adultCount),
            ...(totalChildCount.length > 0 && { children: totalChildCount }),
          },
        ],
        type,
      };

      this.$store.commit("saveCheckInDate", new Date(checkInDate));
      this.$store.commit("saveCheckOutDate", new Date(checkOutDate));
      this.$store.commit("updateChildrenAges", totalChildCount);
      this.$store.commit("saveHotelChildCount", totalChildCount.length);
      this.$store.commit("saveHotelAdultCount", Number(adultCount));
      this.$store.commit("saveSelectedLocation", saveSelectedLocationPayload);

      await this.$store.dispatch(propertiesApiMethod, searchPropertiesPayload);

      const hotels: Property[] = this.$store.getters.hotels;
      const sortedHotels: Property[] = [...hotels].sort(
        (a: Property, b: Property) => a.gross_price.value - b.gross_price.value
      );
      const savePriceRangePayload = { min: 0, max: 0 };

      if (sortedHotels.length > 0) {
        savePriceRangePayload.min = sortedHotels[0].gross_price.value;
        savePriceRangePayload.max =
          sortedHotels[sortedHotels.length - 1].gross_price.value;
      }

      this.$store.commit("savePriceRange", savePriceRangePayload);

      const selectedFilters: SearchFilters =
        this.$store.getters.selectedFilters;

      this.minPriceRange = savePriceRangePayload.min;
      this.maxPriceRange = savePriceRangePayload.max;

      const filters: SearchFilters = selectedFilters;
      filters.rangeSlider = savePriceRangePayload.max;

      this.localSelectedFilters = filters;
    },
    onSavePropertiesHandler(): void {
      const properties: Property[] = this.$store.getters.hotels;
      const filteredData = this.handleFilter(
        this.localSelectedFilters,
        properties
      );
      this.filteredProperties = filteredData;
    },

    getPropertyDetailRouteHandler(property: string): void {
      const propertyDetails: HotelPropertyDetails =
        this.$store.getters.propertyDetails;

      const {
        hotel_adult_count,
        hotel_child_count,
        selectedLocation,
        checkin_date,
        checkout_date,
      } = propertyDetails;

      const query = formatQueryParamsforHotelSearch(
        hotel_adult_count,
        hotel_child_count,
        selectedLocation,
        checkin_date,
        checkout_date
      );

      const path = `${formatStringToRoutePath(PATH.HOTEL_PROPERTY_VIEW, {
        id: selectedLocation.search_id,
      })}`;

      const payload = {
        to: `${selectedLocation.display_name}, ${selectedLocation.sub_display_name}`,
        type: selectedLocation.type,
        "check-in": checkin_date,
        "check-out": checkout_date,
        "adult-travler-count": hotel_adult_count,
        "child-travler-count": hotel_child_count,
      };

      analyticsService.logActionEvent(
        HOTEL_ANALYTICS_EVENTS.HOTEL_SHOW_DETAILS,
        payload
      );

      const route = `${path}${formatQueryPath(query)}&property=${property}`;

      this.$router.push(route);
    },

    handleRemoveChip(
      index: number,
      filter: "propertyRating" | "bookingPolicy" | "supplier"
    ): void {
      const selectedFilter = this.localSelectedFilters[filter];
      selectedFilter.splice(index, 1);
    },
    handleFilter(
      filters: SearchFilters,
      properties: Array<Property>
    ): Property[] {
      let temp = properties;

      temp = temp.filter((item: Property) =>
        filters.propertyRating
          .map((rating) => `${rating}.0`)
          .includes(item.rating.toString())
      );

      temp = temp.filter((item: Property) =>
        filters.supplier.includes(item.supplier)
      );

      temp = temp.filter((item: Property) => {
        if (filters.bookingPolicy.length > 1) {
          return true;
        } else if (filters.bookingPolicy.includes("non-refundable")) {
          return item.non_refundable;
        } else if (filters.bookingPolicy.includes("refundable")) {
          return !item.non_refundable;
        } else {
          return false;
        }
      });

      temp = temp.filter((property: Property) => {
        return (
          property.gross_price.value >= this.minPriceRange &&
          property.gross_price.value <= filters.rangeSlider
        );
      });

      return temp;
    },
    handleUpdateRange(val: number): void {
      this.localSelectedFilters.rangeSlider = val;
    },
    showNoResult(): boolean {
      const isLoading = this.isPropertiesFetching;
      const hotels: [] = this.$store.getters.hotels;
      return !isLoading && hotels.length < 1;
    },
    searchedResultsNotFound(): boolean {
      const isLoading = this.isPropertiesFetching;
      const hotelResponse: HotelPropertyResponse =
        this.$store.state.hotelModule.propertyResponse;

      return (
        !isLoading &&
        hotelResponse.hotels.length > 1 &&
        (hotelResponse.searched_property_not_found ?? false)
      );
    },

    onChangeLoaderMessageHandler() {
      const index = this.loaderMessageIndex;

      this.loaderMessageIndex += 1;

      if (index >= this.loaderMessages.length - 1) {
        this.loaderMessageIndex = 0;
      }
    },
    getPrepaymentText(issue_now_pay_later: boolean) {
      return issue_now_pay_later ? "No prepayment needed" : "";
    },
    formatRefundable(non_refundable: boolean): "Non-Refundable" | "Refundable" {
      return non_refundable ? "Non-Refundable" : "Refundable";
    },
    formatAddress(address: string, city: string, country: string): string {
      return `${address}, ${city}, ${country}`;
    },
    formatPrice(grossPrice: number, default_currency_code: string): string {
      return `${default_currency_code} ${formatNumber(grossPrice)}`;
    },
    formatUrl(url: string): string {
      return formatImageUrl(url);
    },
    formatRating(rating: number | string): number {
      return Number(rating);
    },
  },
  watch: {
    localSelectedFilters: {
      handler: function (filters) {
        const properties: Property[] = this.$store.getters.hotels;
        const filteredData = this.handleFilter(filters, properties);
        this.filteredProperties = filteredData;
      },
      deep: true,
    },
    "$route.query": {
      handler: async function () {
        if (this.allowRouterQueryRequest) {
          await this.onFetchPropertiesHandler();
          this.onInitiateScrollViewHandler();
        }
      },
      immediate: true,
    },
  },
  created() {
    const locations = getPreHotelsSuggestions();
    const formattedLocations = locations.map((item: HotelSearchSuggestion) => {
      return {
        ...item,
        label: `${item.display_name}, ${item.sub_display_name}`,
      };
    });
    this.$store.commit("saveLocations", formattedLocations);
  },
  beforeRouteLeave() {
    this.allowRouterQueryRequest = false;
  },
  unmounted() {
    this.$store.commit("toggleIsPropertiesFetching", false);
    this.loaderMessageIndex = 0;
  },
});
</script>

<style lang="css" scoped>
.hotel-search-list-item {
  margin: 20px 0;
}

.htfade-enter-active,
.htfade-leave-active {
  transition: opacity 0.5s ease;
}

.htfade-enter-from,
.htfade-leave-to {
  opacity: 0;
}
</style>
