export const LOADER_MESSAGES = [
  "Plotting the course to your next adventure...",
  "Scanning the landscapes for your ideal stay...",
  "Preparing our database to fetch you the top hotel deals...",
  "Exploring through options to bring you the best accommodations...",
  "Gathering information on the latest hotel specials for you...",
  "Coordinating with hotels to secure your lodging plans...",
  "Get ready to relax as we find your perfect hotel...",
  "Checking the map for unbeatable deals on accommodations...",
  "Getting ready for check-in with your hotel options...",
  "Embarking on a quest to find your dream destinations...",
  "Searching high and low to secure fantastic hotel deals for you...",
  "Navigating through options to find your perfect room...",
  "On standby to present the best hotel deals for you...",
  "Setting the bar high to snag low rates for you...",
  "Enhancing your travel experience with exclusive hotel offers...",
  "Wandering to bring you closer to your dream stay...",
  "Gathering resources to plan your perfect hotel itinerary...",
  "Booking now: Incredible stays at incredible prices...",
  "Your guide to comfort: Finding hotels that suit you best...",
  "Final call for luxury at affordable prices...",
];
