<template>
  <AgHeading title="Cancellation Policy" variant="h3" test-id="" />
  <AgDiv v-show="freeCancellationDate">
    <p>Free Cancellation until {{ freeCancellationDate }}</p>
  </AgDiv>
  <AgDiv
    style="display: flex; justify-content: space-between"
    v-for="(term, index) in nonRefundablePolicies"
    :key="index"
  >
    <p>From {{ formatDate(term.start_date) }}</p>
    <p>
      {{
        getPrice(
          term.cancellation_amount.value,
          term.cancellation_amount.currency
        )
      }}
    </p>
  </AgDiv>
  <AgDiv style="display: flex; float: right">
    <AGButton
      test-id=""
      @click="handleCloseDialog"
      variant="danger"
      type="button"
      >Close</AGButton
    >
    <AGButton
      style="margin-left: 0.5cm"
      test-id=""
      @click="handleContinue"
      variant="primary"
      type="button"
      >Continue</AGButton
    >
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  RoomCancellationTerm,
  HotelDetails,
} from "@/ag-portal-common/types/hotel";
import { formatNumber } from "@/ag-portal-common/utils/helpers";
import { format } from "date-fns";

export default defineComponent({
  name: "CancellationDialog",
  props: {
    handleContinue: {
      type: Function,
      required: true,
    },
    handleCloseDialog: {
      type: Function,
      required: true,
    },
  },
  methods: {
    formatDate(date: string) {
      return date ? format(new Date(date), "MMM dd yyyy") : "";
    },
    getPrice(amount: number | string, currency: string) {
      return currency + " " + formatNumber(amount);
    },
  },
  computed: {
    bookingDetails(): HotelDetails {
      return this.$store.getters.bookingDetails;
    },
    freeCancellationDate(): string {
      const date = this.cancellationTerms?.find(
        (x: RoomCancellationTerm) => x.cancellation_amount.value <= 0
      )?.end_date;
      return date ? format(new Date(date), "MMM dd yyyy") : "";
    },
    nonRefundablePolicies(): RoomCancellationTerm[] {
      const items = this.cancellationTerms?.filter(
        (x) => x.cancellation_amount.value > 0
      );
      return items;
    },
    cancellationTerms(): Array<RoomCancellationTerm> {
      const bookingDetails = this.$store.getters.bookingDetails as HotelDetails;
      return bookingDetails?.room?.cancellation_terms;
    },
  },
});
</script>
