<template>
  <MCard class="m-hotel-search-list-item">
    <img
      :src="hotelThumbnail"
      @error="onHotelThumbnailErrorHandler"
      loading="lazy"
      class="m-hotel-search-list-thumbnail"
    />
    <div class="m-hotel-search-list-item-info">
      <div class="m-hotel-search-list-content">
        <div>
          <div class="m-hotel-search-list-content-header">
            <MTypography class="property-name m-textoverflow" type="title">
              {{ title }}
            </MTypography>
            <div class="review">
              <MIcon
                v-for="index in hotelRating"
                :key="index"
                class="review-icon"
                name="m-star"
                width="20"
                height="20"
              />
            </div>
          </div>

          <MTypography class="property-address" type="body" v-show="address">
            <MIcon
              class="location-icon"
              name="m-location"
              width="20"
              height="20"
            />
            <MTypography type="body" class="content">
              {{ address }}
            </MTypography>
          </MTypography>
        </div>

        <div class="m-hotel-search-list-action-info">
          <MChip v-show="supplierName">
            {{ supplierName }}
          </MChip>
          <MChip :variant="refundTypeVariant" v-show="refundType">
            {{ refundType }}
          </MChip>
          <MChip variant="success" v-show="prepaymentText">
            {{ prepaymentText }}
          </MChip>
        </div>
      </div>
      <div class="m-hotel-search-list-action">
        <div class="m-hotel-search-list-action-content">
          <MTypography type="label" v-show="startingFromText">
            {{ startingFromText }}
          </MTypography>

          <MTypography type="title" v-show="price">
            {{ price }}
          </MTypography>

          <MTypography type="body" v-show="daysInfo">
            {{ daysInfo }}
          </MTypography>

          <MTypography type="label" v-show="travelerInfo">
            {{ travelerInfo }}
          </MTypography>
        </div>

        <MButton
          class="m-hotel-search-list-action-button"
          @click="$emit('on:click')"
        >
          Show Details
        </MButton>
      </div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  MCard,
  MTypography,
  MButton,
  MChip,
  MIcon,
} from "@aeroglobe/ag-core-ui";

import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";

import DEFAULT_IMAGE from "../../../assets/images/hotel-no-photo.png";

export default defineComponent({
  name: "MHotelSearchListItem",
  components: {
    MCard,
    MTypography,
    MButton,
    MChip,
    MIcon,
  },
  props: {
    thumbnail: {
      type: String,
    },
    title: {
      type: String,
    },
    address: {
      type: String,
    },
    rating: {
      type: Number,
    },
    supplierName: {
      type: String,
    },
    refundType: {
      type: String,
    },
    prepaymentText: {
      type: String,
    },
    startingFromText: {
      type: String,
    },
    price: {
      type: String,
    },
    daysInfo: {
      type: String,
    },
    travelerInfo: {
      type: String,
    },
  },
  emits: ["on:click"],
  data() {
    return {
      hotelThumbnail: this.thumbnail,
      hotelRating: Number(this.rating ?? 0),
    };
  },
  computed: {
    refundTypeVariant(): ChipVariant {
      const refundType: string = (this.refundType ?? "").toLowerCase();

      switch (refundType) {
        case "non-refundable":
          return "error";

        default:
          return "info";
      }
    },
  },
  methods: {
    onHotelThumbnailErrorHandler() {
      this.hotelThumbnail = DEFAULT_IMAGE;
    },
  },
});
</script>

<style scoped>
.m-hotel-search-list-item {
  width: 100% !important;
  height: 200px;
  padding: 0 !important;

  border-radius: 16px !important;
  border: 1px solid var(--m-divider-color) !important;

  --item-background-color-active: #10b9811a !important;
  display: flex;
}

.m-hotel-search-list-item-info {
  flex: 1;

  display: flex;
}

.m-hotel-search-list-thumbnail {
  width: 198px;
  height: 198px;
  object-fit: cover;
  border-radius: 16px 0 0 16px;
}

.m-hotel-search-list-content {
  flex: 1;
  height: 100%;

  padding: 12px 20px;

  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
}

.m-hotel-search-list-content-header {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.m-hotel-search-list-content-header .property-name {
  font-weight: 400;
  flex: 1;
  padding-right: 8px;
  width: 0;
}
.m-hotel-search-list-content-header .review-icon {
  color: gold;
}
.m-hotel-search-list-content-header .property-price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.m-hotel-search-list-content-header .property-details {
  text-align: right;
}

.m-hotel-search-list-content .property-address {
  display: flex;
  gap: 6px;
}
.m-hotel-search-list-content .property-address .location-icon {
  width: 20px;
  height: 20px;

  margin-top: 4px;

  color: var(--m-primary-color);
}
.m-hotel-search-list-content .property-address .content {
  font-size: 14px;
  font-weight: 400;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-hotel-search-list-action-info {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.m-hotel-search-list-action {
  width: 200px;
  height: 100%;

  background-color: var(--item-background-color-active);
  border-radius: 0 16px 16px 0;

  padding: 20px;

  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
}

.m-hotel-search-list-action-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@media screen and (max-width: 1024px) {
  .m-hotel-search-list-thumbnail {
    height: auto;
  }

  .m-hotel-search-list-item {
    height: auto;
  }
  .m-hotel-search-list-item-info {
    flex-direction: column;
  }

  .m-hotel-search-list-action {
    width: 100%;
    border-radius: 0 0 16px 0;

    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 786px) {
  .m-hotel-search-list-item {
    flex-wrap: wrap;
  }

  .m-hotel-search-list-thumbnail {
    width: 100%;
    height: 180px;

    border-radius: 16px 16px 0 0;
  }

  .m-hotel-search-list-action {
    border-radius: 0 0 16px 16px;
  }
}
</style>
