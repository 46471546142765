<template>
  <MCard class="ht-loader">
    <Transition name="htfade" mode="out-in">
      <div class="label" v-if="message" :key="message">
        {{ message }}
      </div>
    </Transition>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { MCard } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "MHotelSearchLoader",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  components: {
    MCard,
  },
});
</script>

<style scoped lang="scss">
.ht-loader .label {
  text-align: center;
  font-weight: 800;
  transition: opacity 0.5s ease;
  height: 24px;
}

.htfade-enter-active,
.htfade-leave-active {
  transition: opacity 0.5s ease;
}

.htfade-enter-from,
.htfade-leave-to {
  opacity: 0;
}
</style>
