import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CancellationDialogBody = _resolveComponent("CancellationDialogBody")!
  const _component_OtpDialogBody = _resolveComponent("OtpDialogBody")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    persistent: "",
    "is-open": _ctx.isOpen,
    "modal-width": "400px"
  }, {
    body: _withCtx(() => [
      (_ctx.showCancellation)
        ? (_openBlock(), _createBlock(_component_CancellationDialogBody, {
            key: 0,
            "handle-continue": _ctx.handleContinue,
            "handle-close-dialog": _ctx.handleCloseIssuanceDialog
          }, null, 8 /* PROPS */, ["handle-continue", "handle-close-dialog"]))
        : (_openBlock(), _createBlock(_component_OtpDialogBody, {
            key: 1,
            onHandleCloseDialog: _ctx.handleCloseIssuanceDialog
          }, null, 8 /* PROPS */, ["onHandleCloseDialog"]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open"]))
}