import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPriceSummary = _resolveComponent("AgPriceSummary")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_heading, {
        variant: "h2",
        title: "Price Summary",
        class: "margin_bottom_10"
      }),
      _withDirectives(_createVNode(_component_AgPriceSummary, {
        "test-id": "",
        info: _ctx.propertName,
        price: _ctx.formattedPrices
      }, null, 8 /* PROPS */, ["info", "price"]), [
        [_vShow, true]
      ]),
      _createVNode(_component_AgDiv, { class: "flight_cart_info margin_bottom_10" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTravelersCount), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _renderSlot(_ctx.$slots, "hoteltDetailsActionButtons")
    ]),
    _: 3 /* FORWARDED */
  }))
}