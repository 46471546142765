import { MutationTree } from "vuex";
import { IHotelSearch } from "../interfaces/hotels.interface";
import {
  BookingDetailsResponse,
  HotelPropertyResponse,
  HotelSearchSuggestion,
  HotelTraveler,
  PropertyDetail,
  UpdateTravelerType,
} from "@/ag-portal-common/types/hotel";

const mutations: MutationTree<IHotelSearch> = {
  setLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  enableIsCancellingConfirmedBooking(state: IHotelSearch) {
    state.isCancellingConfirmedBooking = true;
  },
  disableIsCancellingConfirmedBooking(state: IHotelSearch) {
    state.isCancellingConfirmedBooking = false;
  },
  enableIsPayingForBooking(state: IHotelSearch) {
    state.isPayingForBooking = true;
  },
  disableIsPayingForBooking(state: IHotelSearch) {
    state.isPayingForBooking = false;
  },
  toggleIsPropertiesFetching(state, payload: boolean) {
    state.isPropertiesFetching = payload;
  },
  loadingPropertyDetail(state, payload: boolean) {
    state.isPropertyDetailFetching = payload;
  },
  loadingInititateBooking(state, payload: boolean) {
    state.initiateBookingLoading = payload;
  },
  setConfirmBookingLoading(state, payload: boolean) {
    state.isConfirmBookingLoading = payload;
  },
  setHotelIssueLoading(state, payload: boolean) {
    state.isHotelIssueLoading = payload;
  },
  setResendOtpLoading(state, payload: boolean) {
    state.isHotelOtpResendLoading = payload;
  },
  savePropertyDetail(state, payload: PropertyDetail) {
    state.propertyDetail = payload;
  },
  saveProperties(state, payload: HotelPropertyResponse) {
    state.propertyResponse = payload;
  },
  clearProperties(state) {
    state.propertyResponse.hotels = [];
  },
  loadingLocations(state, payload: boolean) {
    state.isLocationsFetching = payload;
  },
  saveLocations(state, locations: Array<HotelSearchSuggestion>) {
    state.isLocationsFetching = false;
    state.locations = locations;
  },
  saveSelectedLocation(state, payload: HotelSearchSuggestion) {
    state.selectedLocation = payload;
  },
  saveCheckInDate(state, payload: Date) {
    state.checkin_date = payload;
  },
  saveCheckOutDate(state, payload: Date) {
    state.checkout_date = payload;
  },
  saveHotelAdultCount(state, payload: number) {
    state.hotel_adult_count = payload;
  },
  saveHotelChildCount(state, payload: number) {
    state.hotel_child_count = payload;
  },
  saveHotelChildAge(
    state,
    payload: {
      index: number;
      age: number;
    }
  ) {
    state.hotel_child_age[payload.index] = payload.age;
  },
  savePropertiesResponse(state, payload: HotelPropertyResponse) {
    state.propertyResponse = payload;
  },
  updateChildrenAges(state, payload: any) {
    state.hotel_child_age = payload;
  },
  savePriceRange(state, payload: { max: number; min: number }) {
    state.maxPriceRange = payload.max;
    state.minPriceRange = payload.min;
  },
  saveBookingDetailsResponse(state, payload: BookingDetailsResponse) {
    state.bookingDetailsResponse = payload;
  },
  saveTravelers(state, payload: HotelTraveler[]) {
    state.travelers = payload;
  },
  updateTaveler(state, payload: UpdateTravelerType) {
    const updatedTraveler: any = state.travelers[payload.index];
    updatedTraveler[payload.key] = payload.value;
    state.travelers[payload.index] = updatedTraveler;
  },
  updateMobileNumber(state, payload: string) {
    state.mobileNumber = payload;
  },
  updateEmail(state, payload: string) {
    state.email = payload;
  },
  enableIsDownloadingVoucher(state: IHotelSearch) {
    state.isDownloadingVoucher = true;
  },
  disableIsDownloadingVoucher(state: IHotelSearch) {
    state.isDownloadingVoucher = false;
  },
  saveVoucherUrl(state, payload: string) {
    state.voucherUrl = payload;
  },
};

export default mutations;
